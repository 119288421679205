import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Head from "next/head";
import { useRouter } from "next/router";

import { tenantPrimaryColors, TENANTS_LIST } from "constants/index";
import { useFormattedMessage, useUtils } from "hooks";

const FINX_COOKIE = require("common/constants");

const messages = {
    epContentWithHistory: "something_went_wrong_use_back_button",
    epContentWithOutHistory: "go_to_landing_page",
    epGoBackButtonText: "go_back",
    epLandingPageButtonText: "back_to_home",
    epSupportEmail: "tenant_smtp_user",
};

const PageNotFoundErrorPage = () => {
    const { formatMessage } = useFormattedMessage();
    const router = useRouter();

    const {
        isPlatformSourceFromApp,
        tenant: { Name: currentTenant, TechnicalName: technicalName },
    } = useUtils();

    const [isGotoHome, setIsGotoHome] = useState(true);

    useEffect(() => {
        if (router.length > 2) {
            const oldUrl = Cookies.get(FINX_COOKIE.OLD_URL);
            if (oldUrl) {
                setIsGotoHome(false);
            }
        }
    }, []);

    const goToPrevUrl = () => {
        if (isPlatformSourceFromApp) {
            window?.ReactNativeWebView?.postMessage("reload");
        } else {
            if (isGotoHome) {
                router.push("/");
            } else {
                router.back();
            }
        }
    };

    return (
        <>
            <Head>
                <title>{`404 | ${technicalName == TENANTS_LIST.FINEXITY ? "FINEXITY" : currentTenant}`}</title>
            </Head>
            <div className="main-container page-not-found-width d-flex align-center">
                <div className="w-100 d-flex flex-wrap">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="mx-auto"
                        width="218.055"
                        height="128.489"
                        viewBox="0 0 218.055 128.489"
                    >
                        <g transform="translate(17835.128 10581.934)">
                            <g transform="translate(178 109)">
                                <path
                                    class="a"
                                    fill="none"
                                    d="M831.264,1506.5s-12.411,4.1-22.085,6.751-16.612,3.864-16.612,3.864"
                                    transform="translate(-18803.959 -12115.055)"
                                    stroke="#171926"
                                    stroke-linecap="round"
                                    stroke-width="3px"
                                />
                                <g transform="translate(-17967.57 -10631.344) rotate(-14)">
                                    <rect
                                        class="b"
                                        fill="none"
                                        width="6.243"
                                        height="40.745"
                                        rx="3"
                                        transform="translate(33.004 -0.728)"
                                        stroke-width="3px"
                                        stroke="#000"
                                    />
                                    <g class="b" fill="none" transform="translate(9.928 2.179)" stroke-width="3px" stroke="#000">
                                        <path
                                            class="e"
                                            d="M6,0H24.428a0,0,0,0,1,0,0V35.713a0,0,0,0,1,0,0H6a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0Z"
                                            stroke="none"
                                        />
                                        <path
                                            fill="none"
                                            d="M6,1.5H21.428a1.5,1.5,0,0,1,1.5,1.5V32.713a1.5,1.5,0,0,1-1.5,1.5H6a4.5,4.5,0,0,1-4.5-4.5V6A4.5,4.5,0,0,1,6,1.5Z"
                                        />
                                    </g>
                                    <g transform="translate(0 6.972)">
                                        <path
                                            class="b"
                                            fill="none"
                                            d="M3,0H9.243a0,0,0,0,1,0,0V22.454a0,0,0,0,1,0,0H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                                            transform="translate(2 2)"
                                            stroke-width="3px"
                                            stroke="#000"
                                        />
                                    </g>
                                </g>
                                <path
                                    class="c"
                                    d="M3,0H15.169a0,0,0,0,1,0,0V9.259a0,0,0,0,1,0,0H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                                    transform="translate(-17977.244 -10612.716) rotate(-14)"
                                    stroke-width="3px"
                                    stroke="#000"
                                    fill="#fff"
                                />
                            </g>
                            <g transform="translate(178 109)">
                                <g transform="translate(1)">
                                    <path
                                        class="a"
                                        fill="none"
                                        d="M831.264,1506.5s-12.411,4.1-22.085,6.751-16.612,3.864-16.612,3.864"
                                        transform="translate(-17005.242 -9139.489) rotate(180)"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                    />
                                    <g transform="translate(-17841.631 -10623.2) rotate(166)">
                                        <rect
                                            class="b"
                                            fill="none"
                                            width="6.243"
                                            height="40.745"
                                            rx="3"
                                            transform="translate(33.004 -0.728)"
                                            stroke-width="3px"
                                            stroke="#000"
                                        />
                                        <g
                                            class="b"
                                            fill="none"
                                            transform="translate(9.928 2.179)"
                                            stroke-width="3px"
                                            stroke="#000"
                                        >
                                            <path
                                                class="e"
                                                d="M6,0H24.428a0,0,0,0,1,0,0V35.713a0,0,0,0,1,0,0H6a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0Z"
                                                stroke="none"
                                            />
                                            <path
                                                fill="none"
                                                d="M6,1.5H21.428a1.5,1.5,0,0,1,1.5,1.5V32.713a1.5,1.5,0,0,1-1.5,1.5H6a4.5,4.5,0,0,1-4.5-4.5V6A4.5,4.5,0,0,1,6,1.5Z"
                                            />
                                        </g>
                                        <g transform="translate(0 6.972)">
                                            <path
                                                class="b"
                                                fill="none"
                                                d="M3,0H9.243a0,0,0,0,1,0,0V22.454a0,0,0,0,1,0,0H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                                                transform="translate(2 2)"
                                                stroke-width="3px"
                                                stroke="#000"
                                            />
                                        </g>
                                    </g>
                                    <path
                                        class="c"
                                        d="M3,0H15.169a0,0,0,0,1,0,0V9.259a0,0,0,0,1,0,0H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0Z"
                                        transform="translate(-17831.957 -10641.828) rotate(166)"
                                        stroke-width="3px"
                                        stroke="#000"
                                        fill="#fff"
                                    />
                                </g>
                                <g transform="translate(-17907.031 -10647.126)">
                                    <g transform="matrix(1, -0.017, 0.017, 1, 0, 0.371)">
                                        <path
                                            class="d"
                                            d="M3.637,0H20.15a0,0,0,0,1,0,0V7.275a0,0,0,0,1,0,0H3.637A3.637,3.637,0,0,1,0,3.637v0A3.637,3.637,0,0,1,3.637,0Z"
                                            transform="translate(0 4.533) rotate(-13)"
                                            stroke-width="3px"
                                            stroke="#000"
                                            fill={tenantPrimaryColors[technicalName]?.primary} // change color for tenant
                                        />
                                    </g>
                                    <g transform="matrix(1, -0.017, 0.017, 1, 5.684, 22.841)">
                                        <path
                                            class="d"
                                            d="M3.637,0H19.8a0,0,0,0,1,0,0V7.275a0,0,0,0,1,0,0H3.637A3.637,3.637,0,0,1,0,3.637v0A3.637,3.637,0,0,1,3.637,0Z"
                                            transform="translate(0 4.454) rotate(-13)"
                                            stroke-width="3px"
                                            stroke="#000"
                                            fill={tenantPrimaryColors[technicalName]?.primary} // change color for tenant
                                        />
                                    </g>
                                </g>
                            </g>
                            <g transform="translate(325.457 -20541.746) rotate(-60)">
                                <g transform="translate(-17709.564 -10688.5)">
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                    />
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 108.433)"
                                    />
                                </g>
                                <g transform="translate(-17678.195 -10680.095) rotate(30)">
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 0)"
                                    />
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 108.433)"
                                    />
                                </g>
                                <g transform="translate(-17678.195 -10680.095) rotate(30)">
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 0)"
                                    />
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 108.433)"
                                    />
                                </g>
                                <g transform="translate(-17655.229 -10657.127) rotate(60)">
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 0)"
                                    />
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 108.433)"
                                    />
                                </g>
                                <g transform="translate(-17646.822 -10625.756) rotate(90)">
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 0)"
                                    />
                                    <line
                                        class="a"
                                        fill="none"
                                        stroke="#171926"
                                        stroke-linecap="round"
                                        stroke-width="3px"
                                        y2="17.057"
                                        transform="translate(0 108.433)"
                                    />
                                </g>
                            </g>
                        </g>
                    </svg>

                    <div className="w-100 mt-10">
                        <p>
                            {isGotoHome
                                ? formatMessage(messages.epContentWithOutHistory)
                                : formatMessage(messages.epContentWithHistory)}
                            &nbsp;
                            <a className="primary-color" href={`mailto:${formatMessage(messages.epSupportEmail)}`}>
                                {formatMessage(messages.epSupportEmail)}
                            </a>
                        </p>
                        <button className="medium mt-7" onClick={() => goToPrevUrl()}>
                            {isGotoHome
                                ? formatMessage(messages.epLandingPageButtonText)
                                : formatMessage(messages.epGoBackButtonText)}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PageNotFoundErrorPage;
